import React from "react"

import SEO from "../../components/seo"
import ConditionalLayout from "../../components/conditionalLayout"
import TeamdetailModule from "../../components/aboutus/team/teamdetailModule"
import { TeamModel6 } from "../../components/aboutus/team/model"

const BeatricePage = () => (
    <ConditionalLayout>
        <SEO title="About" />
            <TeamdetailModule member={TeamModel6} />
   </ConditionalLayout>
)

export default BeatricePage

